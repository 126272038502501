import { html, LitElement } from "lit";
import { customElement } from "lit/decorators";
import { ClientService } from "../client-service/client-service";
import { IUserInfo } from "../models/user-info";
import { UserAuthenticationService } from "../user-authentication/user-authentication-service";
import { LocalStorageService } from "../local-storage/local-storage-service";
import { HexakaiPlusSubscriptionService } from "../hexakai-plus-subscription/hexakai-plus-subscription-service";
import { ModalService } from "../modal-service/modal-service";
import { ComponentDependencyTree } from "../component-dependency-tree/component-dependency-tree";

@customElement('account-login')
export class AccountLoginComponent extends LitElement {

    // bring in dependencies
    private clientService = ComponentDependencyTree.clientService;
    private userAuthenticationService = ComponentDependencyTree.userAuthenticationService;
    private hexakaiPlusService = ComponentDependencyTree.hexakaiPlusSubscriptionService;
    private modalService = ComponentDependencyTree.modalService;

    private isLoading = true;
    private isLoggedIn = false;
    private userInfo: IUserInfo | null = null

    // Overriding createRenderRoot to use Light DOM
    createRenderRoot() {
        return this; // Renders template into light DOM
    }

    connectedCallback(): void {
        super.connectedCallback();
        if (!this.clientService.getConfig().userProfile.enabled) {
            return;
        }

        // if enabled, load information
        this.userAuthenticationService.getUserInfo().then(async (userInfo) => {
            this.isLoading = false;
            if (!userInfo) {
                this.isLoggedIn = false;
            } else {
                this.isLoggedIn = true;
                this.userInfo = userInfo;
            }

            console.log(`[AccountLoginComponent] user info`, userInfo);

            this.requestUpdate();
        });
    }

    render() {
        if (this.isLoading) {
            return html``;
        }

        let inner = html``;
        if (this.isLoggedIn) {
            inner = this.renderLoggedIn();
        } else {
            inner = this.renderLoggedOut();
        }

        return html`${inner}<hr/>`;
    }

    private renderLoggedIn() {
        const hexakaiPlusMessage = this.clientService.getConfig().hexakaiPlus.enabled
            ? html`, manage your Hexakai Plus subscription, `
            : html` `;

        return html`
            <p>Welcome back, <b>${this.userInfo!.firstName}</b>! Use this page to manage your settings${hexakaiPlusMessage}or log out of your Google account.</p>
            ${this.clientService.getConfig().hexakaiPlus.enabled ? html`<styled-button @click="${() => this.manageHexakaiPlus()}">Manage Hexakai Plus</styled-button>` : ""}
            <styled-button @click="${() => this.onLogoutClick()}">Log Out</styled-button>
        `;
    }

    private renderLoggedOut() {
        const hexakaiPlusMessage = this.clientService.getConfig().hexakaiPlus.enabled
            ? html`and to manage your Hexakai Plus subscription.`
            : html``;

        // googleLoginCredentialCallback is defined in UserAuthenticationService
        return html`
            <p>Sign in or create an account to take advantage of advanced features${hexakaiPlusMessage}.</p>
        `;
    }

    private onLogoutClick(): void {
        this.userAuthenticationService.logOut();
    }

    private async manageHexakaiPlus(): Promise<void> {
        console.debug(`[AccountLoginComponent] manageHexakaiPlus invoked`);
        const modalId = "manage-plus";

        const isSubscribed = await this.hexakaiPlusService.isSubscribed();

        const subHref = await this.hexakaiPlusService.getPaywallUrl();
        const button = isSubscribed ? "" : html`
            <styled-button @click="${() => window.open(subHref, "_blank")}">Subscribe!</styled-button>
        `;

        this.modalService.showModal(
            modalId,
            "Manage Your Subscription",
            html`
                <hexakai-plus-manage></hexakai-plus-manage>
                <div style="display: flex; gap: 1em; flex-wrap: wrap">
                    ${button}<styled-button @click="${() => this.modalService.hideModal(modalId)}">Close</styled-button>
                </div>
            `
        );
    }
    
}